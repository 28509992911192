<template>
    <v-container class="">
      <v-row justify="center" class="blue" v-if="(relatorios_show)">

        <!-- imprimindo: {{ imprimindo }} <br/> -->
        <!-- <center>obj_dados_recibo : {{ obj_dados_recibo }} <br/></center> -->
        <!-- <center> obj_dados_empresa : {{ obj_dados_empresa }} <br/> </center> -->
        <!-- <center> modelo_recibo:{{ modelo_recibo }} <br/></center> -->
         <!-- obj_dados_impressao : {{ obj_dados_impressao }} <br/> -->
        <!-- rel_auto_print : {{ rel_auto_print }} -->
        <!-- {{ checkbox_concluidos }} -->
        <!-- {{ checkbox_celular }} -->
        
        <!-- {{ items_retorno }} -->


          <!-- <v-dialog
              v-model="dialog_print"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
          > -->

  
          <v-card
              elevation="0"
              class=""
              width="100%"
          >
              <v-toolbar
                  dark
                  color="primary"
                  elevation="0"
                  class="no-print"
              >
  
  
                  <v-spacer></v-spacer>

  
                  <v-btn
                      @click="imprimir()"
                      color="primary text--white"
                  >
                  <v-icon color="white"> mdi-printer </v-icon>
                  &nbsp imprimir
                  </v-btn>
  
                  <v-divider class="mx-2" vertical inset></v-divider>
  
  
                  <v-btn
                      color="primary"
                      @click="close()"
                  >
                      
                      <v-icon>mdi-close</v-icon>
                      &nbsp 
                      Fechar
                      
                  </v-btn>
  
              </v-toolbar>
  

              <v-container v-if="(modelo_recibo == 'AUTO')" fluid class="pa-1">
                  <v-row dense class="d-flex justify-center">
                    <v-card
                     width="100%"
                     class="sem_borda grey lighten-5 full"
                    >
                    
                     <v-card-subtitle class="d-flex justify-center pa-0 pt-1">
                      <img
                          alt=".."
                          :src="urlLogo"
                          style="height: 20%; width: 10%;"
                        >
                     </v-card-subtitle>

                      <v-card-title class="d-flex justify-center  pa-0">
                        <h6 v-html='quebra_linha(rel_titulo)' align="center">  </h6>
                      </v-card-title>

                      <v-divider class="mx-10"></v-divider>


                      <v-card-text>
                        <v-data-table
                          v-if="rel_componente == 'v-data-table'"
                          
                          :headers="rel_headers"
                          :hide-default-header="rel_ocultar_header"

                          :items="rel_items"
                          class="elevation-0 align-left" 
                          dense
                          hide-default-footer
                          disable-pagination
                        >
                        </v-data-table>

                      </v-card-text>

                    </v-card>
                  </v-row>
              </v-container>

            
              <v-container v-if="(modelo_recibo == 'RECIBO')" class="">
                <v-row justify="">

                      <!-- <v-card elevation="0" class=""> -->

                        <v-container fluid class="pa-2">
                            <v-row dense class="">


                              <v-col cols="1" class="no-print">
                                <!-- COLUNA ESQUERDA -->
                                <v-card
                                class="no-print mt-1"
                                elevation="0"
                                height="100"
                                >
                                    <v-card class="mt-3 ml-2 grey lighten-4" v-if="0>1">
                                        <v-row>
                                            <v-col cols="3 offset-md-1">
                
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-card> 
                              </v-col>

                              <v-col :cols="(imprimindo) ? auto : 9">
                               <!-- COLUNA CENTRAL -->
                               <v-card
                                class="sem_borda"
                                >
                                <!-- COLUNA CENTRAL -->
                                    <!-- INICIO -->
                                    <v-card
                                    height="6.6cm"
                                    width="18.7cm"
                                    class="grey lighten-4 ml-1 mb-3"
                                    v-for="(item, i ) in objVias"
                                    :key="i"
                                    >
                                    <!-- {{item}}  -->
                                    <v-container fluid class="pa-2">
                                        <v-row dense class="">

                                            <!-- linha recibo basico -->
                                            <v-card
                                            width="13.6cm"
                                            height="3.5cm"
                                            class=" pt-0"
                                            v-if="(recibo_basico)"
                                            >
                                                <center>
                                                    <img
                                                    ref="print_logo"
                                                    :src="urlLogo"
                                                    PATH_LOGO
                                                    style="max-width: auto; height: 2.5cm"
                                                    />
                                                    <!-- src="http://localhost\vue_cfc\public\shalon.png" -->
                                                </center>
                                                <div 
                                                class="ml-1"
                                                style="margin-top: -0.3cm"
                                                >
                                                    <p class="inline h7">CNPJ: {{ obj_dados_empresa.CNPJ }}</p>
                                                    &nbsp
                                                    
                                                    <p class="inline h7">FONES: {{ obj_dados_empresa.FONE1 }} | {{ obj_dados_empresa.CELULAR }} </p>
                                                    &nbsp
                                                    <p class="inline h7"> {{ obj_dados_empresa.EMAIL }} </p>
                                                </div>
                                                
                                                <div 
                                                class="ml-1"
                                                style="margin-top: -0.2cm"
                                                >
                                                    <p class="inline h7" > {{ obj_dados_empresa.ENDERECO_COMPLETO }}</p>
                                                </div>
                                            </v-card>

                                            <v-card
                                            width="4.7cm"
                                            height="3.5cm"
                                            class=" px-2 ml-1"
                                            elevation="2"
                                            v-if="(recibo_basico)"
                                            >

                                            <h3 class="inline">RECIBO</h3> 
                                            
                                            <h6 class="inline ml-1">
                                                {{obj_dados_recibo.CODIGO}}
                                            </h6> 

                                            <div style="display: flex; justify-content: flex-end; margin-top:-25px; margin-bottom:2px">
                                                <h6 class="inline">{{ i + 1}}ª Via</h6> 
                                            </div>

                                            <!-- <h6 class="none">SEQ: 29245</h6> -->

                                            <h2 class="ml-1"> R$ 
                                              {{obj_dados_recibo.double_TOTAL}}
                                            </h2>

                                            <h5 class="none">Recebido em </h5>
                                            <h4 class="inline ml-1">
                                                {{obj_dados_recibo.DATA}}
                                            </h4>
                                            
                                            <!-- <h6 class="inline none">Venc. </h6>
                                            <h5 class="inline"> 20/04/2022 </h5> -->
                                            
                                            </v-card>


                                            <!-- linha recibo alternativo -->
                                            <v-card
                                            width="18.4cm"
                                            height="6.4cm"
                                            class=" pt-0"
                                            v-if="(!recibo_basico)"
                                            elevation="2"
                                            >

                                            
                                            <v-row dense class="">
                                              <v-col class="ml-2">

                                              <div class="mt-2">

                                                <span class="" style="float: left;">
                                                  <span class="fs-30 underline">RECIBO</span>
                                                  <span class="fs-18 ml-4">Nº</span>
                                                  <span class="fs-14 ml-0 bold"> {{ obj_dados_recibo.CODIGO }} </span>
                                                  
                                                  <span class="fs-18 ml-15 pl-15">DATA:</span>
                                                  <span class="fs-18 ml-0 bold"> {{ obj_dados_recibo.DATA }} </span>
                                                </span>
                                                
                                                
                                                <span class="mr-5 pt-3 " style="float: right;">
                                                  <span class="fs-18 ml-4">VALOR:</span>
                                                  <span class="fs-18 ml-0 bold"> R$ {{ obj_dados_recibo.VALOR }} </span>
                                                </span>

                                              </div>
                                             
                                              </v-col>
                                            </v-row>

                                            <v-divider class="mx-2"/>

                                            <v-row dense>
                                              <v-col class="ml-2">
                                                Recebi de: <span class="bold fs-20 ml-1"> {{ obj_dados_empresa.NOME }} </span> ***
                                                <br/>
                                                Referente à: <span class="bold fs-14 ml-1"> {{ obj_dados_recibo.REFERENTE }} </span> ***
                                              </v-col>
                                            </v-row>
                                            
                                            <v-divider class="ma-2"/>

                                            <v-row dense>
                                              <v-col class="ml-2" cols="">
                                                e para clareza firmamos o presente.  <span class="bold fs-14 ml-1"> {{ obj_dados_empresa.CIDADE }}, {{ hoje }} </span>
                                                <br/>
                                                Nome: <span class="bold fs-14 ml-1"> {{ obj_dados_recibo.NOME }} </span>


                                                <br/>
                                                <center style="float: right;" class="pr-5">
                                                <h6 class="none pt-2">____________________________________________ </h6>
                                                <h6 class="none"> 
                                                    Assinatura
                                                </h6>
                                                </center>

                                                <br/>
                                                <span class="fs-12">{{ obj_dados_recibo.RECEBEU }}</span>

                                              </v-col>


                                              <!-- <v-col class="blue">
                                                <center>
                                                <h6 class="none pt-2">____________________________________________ </h6>
                                                <h6 class="none"> 
                                                    {{ obj_dados_recibo.RECEBEU }}
                                                </h6>
                                                </center>
                                              </v-col> -->

                                            </v-row>

                                            <v-row dense class="blue" v-if="0>1">
                                              <v-col>
                                                <center>
                                                <h6 class="none pt-2">____________________________________________ </h6>
                                                <h6 class="none"> 
                                                    {{ obj_dados_recibo.RECEBEU }}
                                                </h6>

                                                </center>
                                              </v-col>
                                            </v-row>
                                            
                                            
                                              
                                            </v-card>


                                          

                                            <v-card
                                              height="1cm"
                                              width="15.4cm"
                                              class=" mt-1 pa-1"
                                              v-if="(recibo_basico)"
                                            >
                                            <!-- <p class="inline cap">Recebemos de  </p> -->
                                            <h6 class="inline none">Recebemos de: </h6>
                                            <h4 class="inline "> {{ obj_dados_recibo.NOME }} </h4>
                                            <!-- <p class="inline cap"> &nbsp CPF:  </p> -->
                                            <!-- <h5 class="inline"> {{ obj_dados_recibo.CPF }} </h5> -->
                                            </v-card>

                                            <v-card
                                            height="1cm"
                                            width="3cm"
                                            class=" mt-1 pa-1"
                                            v-if="(recibo_basico)"
                                            >
                                            <!-- <h6 class="none">PRO: {{ obj_dados_recibo.PROCESSO }} </h6>
                                            <h6 class="none">IDE: {{ obj_dados_recibo.CODIGO_ALUNO }}</h6> -->
                                            </v-card>

                                            <v-card
                                            class=" mt-1 pa-1"
                                            height="1.7cm"
                                            width="12.1cm"
                                            v-if="(recibo_basico)"
                                            >
                                            <h6 class=" none">Referente à: </h6>
                                                <h6 class="inline none bold" v-if="(Object.keys(obj_dados_recibo).length === 0)">
                                                    {{ item_menu.PLANO }}
                                                    - V:{{ getFormatDate(item_menu.DATA_VENCIMENTO) }} 
                                                    - R$ {{ (item_menu.double_TOTAL) }} 
                                                    - {{ item_menu.FORMA_PAGAMENTO }}
                                                    - {{ item_menu.SERVICO_REFERENTE }} 
                                                </h6>
                                                <h6 v-else class="none bold fs-14">
                                                    <!-- recibo unico -->
                                                    {{obj_dados_recibo.REFERENTE}}
                                                </h6>

                                            </v-card>

                                            <v-card
                                            height="1.7cm"
                                            width="6.3cm"
                                            class=" mt-1 pa-1"
                                            v-if="(recibo_basico)"
                                            >
                                            <center>
                                                <h6 class="none pt-2">____________________________________________ </h6>
                                                <h6 class="none"> 
                                                    {{ obj_dados_recibo.RECEBEU }}
                                                </h6>

                                                <h6 class="none pt-1"> {{ obj_dados_empresa.CIDADE }}, {{ hoje }}</h6>
                                            </center>
                                            </v-card>

                                        </v-row>
                                    </v-container>
                                    </v-card>
                                </v-card>  
                              </v-col>

                              <v-col cols="2" class="no-print">
                                <!-- COLUNA DIREITA -->
                                <v-card
                                class="no-print mt-1"
                                width="300"
                                elevation="0"
                                height="100"
                                >

                                    <v-card class="mt-3 ml-2 grey lighten-4">
                                        <v-row align="">
                                            <v-col cols="4 offset-md-1">
                                                    <v-select
                                                    v-model="nVias"
                                                    :items="['1','2']"
                                                    label="Nº de Vias"
                                                    @input="mudaVias(nVias)"
                                                    ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-card>
                              </v-col>


 

                            </v-row>
                        </v-container>
                      <!-- </v-card> -->
                    <!-- </v-dialog> -->
                </v-row>
              </v-container>


              <!-- O PULO DO GATO HAHAHA -->
              <v-container v-else fluid class="pa-1">
              <!-- v-if="(modelo_recibo=='ficha')||(modelo_recibo=='pre-solicitacao')" -->
                  <v-row dense>


                      <!-- COLUNA ESQUERDA -->
                      <v-card
                      class="no-print mt-1"
                      width="5%"
                      elevation="0"
                      height="100"
                      >
                          <v-card class="mt-3 ml-2 grey lighten-4" v-if="0>1">
                              <v-row>
                                  <v-col cols="3 offset-md-1">

                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-card>  

                  <!-- COLUNA PRINCIPAL -->
                  <v-card
                      class="sem_borda red lighten-5 full"
                      min-width="40%"
                      width="19cm"
                      height="26.5cm"
                      v-if="(modelo_recibo == 'RECIBO')"
                  >
                      <v-row 
                      v-for="(item, i) in array_to_print"
                      :key="i"
                      dense
                      no-gutters
                      >

                      
                      <!-- QUANDO TEM MAIS DE UMA COLUNA NA MESMA LINHA -->
                      <v-col 
                          v-if="(item.colunas)"
                          v-for="(sub_item, sub_i) in item.colunas"
                          :key="sub_i"
                          :class="[sub_item.col_class  + class_default]"
                          :cols="sub_item.col_cols"
                      >

                        <div  
                          :style= "[{'height':sub_item.card_h, 'width': sub_item.card_w}]"
                          :class="[sub_item.card_class]"
                          >
                          
                          

                          <img v-if="sub_item.tag=='img'" :src="urlLogo" :style= "[{'height': sub_item.h, 'max-width': sub_item.w}]"/>

                          <div v-if="sub_item.tag=='div'" :class="sub_item.class">  


                              <div
                                v-for="(F, Findex) in fields_coluna"
                                :key="Findex"
                                class="inline"
                              >


                                  <span :class="(sub_item['boldB'+F]=='S') ? 'bold': sub_item.class"> {{sub_item['B'+F]}}  </span>

                                  <span :class="(sub_item['boldL'+F]=='S') ? 'bold': sub_item.class"> {{sub_item['LABEL'+F]}}  <br v-if="sub_item['br'+F]"/> </span>

                                  <div v-if="(sub_item.table)" class="inline">

                                      <span :class="(sub_item['boldF'+F]=='S') ? 'bold': sub_item.class">   
                                          <span v-if="sub_item['F'+F+'_DATE']=='S'">
                                              {{ getFormatDate(sub_item.table[sub_item['F'+F]]) }}
                                          </span>
                                          <span v-else>
                                              {{ sub_item.table[sub_item['F'+F]] }}
                                          </span>
                                              
                                      </span>                                
                                  </div>
                              </div>
                          </div>
                      </div>


                      <v-divider v-if="sub_item.tag=='divider'" :class="sub_item.class"></v-divider>

                      <div :class="[sub_item.class]" v-if="sub_item.tag=='corta_linha'" >
                        <center>
                          <v-divider  class="grey mx-15"></v-divider>
                          <v-icon x-small style="margin-top:-30px">
                              mdi-scissors-cutting 
                          </v-icon>
                        </center>
                      </div>
                      
                      </v-col>



                      <!-- PERCORRER UMA SUB TABELA = TABELA2 -->
                      <v-col v-if="item.sub_tabela" cols="12" :class="class_default">
                          <v-row dense no-gutters
                              v-for="(sub_item, sub_i) in item.sub_tabela[0].table.length"
                              :key="sub_i"
                              :class="[sub_item.col_class]"
                              :cols="sub_item.col_cols"
                          >
                      
                          <!-- :style= "[isMobile ? {'height':'55px', 'margin-top': '0px'} : {'height':'40px'}]" -->
                          
                          <!-- {{ sub_i }} -->
                              
                                  <v-col
                                      v-for="(sub_item2, sub_i2) in item.sub_tabela"
                                      :key="sub_i2"
                                      class=""
                                      :cols="sub_item2.col_cols"
                                      :class="[sub_item2.col_class]"
                                  >

                                      <div v-if="sub_item2.tag=='div'" :class="sub_item2.class">  

                                          <div
                                          v-for="(F, Findex) in fields_coluna"
                                          :key="Findex"
                                          class="inline"
                                          >
                                          <!-- {{  F  }} -->

                                              <span :class="[(sub_item2['boldB'+F]=='S') ? 'font-weight-bold':'', sub_item2.class]"> {{sub_item2['B'+F]}}  </span>

                                              <span :class="[(sub_item2['boldF'+F]=='S') ? 'font-weight-bold':'', sub_item2.class]">   

                                                  <span v-if="(sub_item2.vELSE) && (sub_item2.table[sub_i][sub_item2.vIFcampo] != sub_item2.vIFvalor) && ( sub_item2.table[sub_i][sub_item2['F'+F]])">
                                                      {{  sub_item2.vELSE}}
                                                  </span>

                                                  <span v-else-if="(sub_item2.vIFcampo) && (sub_item2.table[sub_i][sub_item2.vIFcampo] == sub_item2.vIFvalor) && ( sub_item2.table[sub_i][sub_item2['F'+F]])">
                                                      <span> {{ sub_item2['Bif'+F] }}</span>
                                                      {{ sub_item2.table[sub_i][sub_item2['F'+F]] }}
                                                      <span> {{ sub_item2['Aif'+F] }}</span>
                                                  </span>
                                                  

                                                  <span v-else>
                                                      <span v-if="sub_item2['F'+F+'_DATE']=='S'">
                                                          {{ getFormatDate(sub_item2.table[sub_i][sub_item2['F'+F]]) }}
                                                      </span>
                                                      <span v-else>
                                                          {{ sub_item2.table[sub_i][sub_item2['F'+F]] }}
                                                      </span>
                                                  </span>

                                              </span>
                                          </div>

                                          
                                      </div>

                                      <v-divider v-if="sub_item2.tag=='divider'" :class="sub_item2.class"></v-divider>

                                  </v-col>

                          </v-row>
                      </v-col>



                      <!-- V-ELSE   DEIXAR ESSE DAQUI SEMPRE NO FINAL -->
                      <!-- SOMENTE 1 COLUNA NA LINHA -->
                      <v-col 
                          v-else 
                          :class="[item.col_class + class_default]"
                          :cols="item.col_cols"
                      >
                          <img v-if="item.tag=='img'" :src="urlLogo" :style= "[{'height': item.h, 'max-width': item.w}]"/>

                          <div v-if="item.tag=='div'" :class="[item.class]">  
                              <div :class="(item.boldB1=='S') ? 'font-weight-bold':''">   {{item.B1}}  </div>
                              <div :class="(item.boldL1=='S') ? 'font-weight-bold':''">   {{item.LABEL1 }} </div>
                          </div>
                          
                          <v-divider v-if="item.tag=='divider'" :class="item.class"></v-divider>
                      </v-col>



                      </v-row>


                      <!-- FOOTER -->
                      <v-row 
                      v-for="(item, index) in array_to_print"
                      :key="item.CODIGO"
                      dense
                      no-gutters
                      class="footer_class"
                  >
                          <v-col 
                              v-if="item.footer"
                              v-for="(sub_item, sub_i) in item.footer"
                              :key="sub_i"
                              :class="[sub_item.col_class]"
                              :cols="sub_item.col_cols"
                          >
                              <div v-if="sub_item.tag=='div'" :class="sub_item.class">  
                                  <div
                                    v-for="(F, Findex) in fields_coluna"
                                    :key="Findex"
                                    class=""
                                  >
                                  <!-- class="inline" -->
                                      <span :class="[(sub_item['boldB'+F]=='S') ? 'font-weight-bold':'' , sub_item.class]">   {{sub_item['B'+F]}}  </span>
                                      <span :class="[(sub_item['boldL'+F]=='S') ? 'font-weight-bold':'' , sub_item.class]">   {{sub_item['LABEL'+F] }} </span>
                                  </div>
                              </div>
                          </v-col>
                      </v-row>

                  </v-card>

                  </v-row>
              </v-container>

          </v-card>
  
  
          <!-- </v-dialog> -->
      </v-row>
  
    </v-container>
  </template>
  
  <script>
  
  // import DSirotinas from '../assets/js/DSi_rotinas'//chamo funcao do arquivo
  import {get_data_hora} from '../assets/js/DSi_basico'
  import DSisql from '../assets/js/DSi_sql'//chamo funcao do arquivo

  
  
  export default {
  
  
      data: () => ({
  

          obj_dados_impressao: {},
          obj_dados_recibo: {},

          nVias: '2',
          objVias:[],
          imprimindo: false,

          hoje: '',

          recibo_basico: true,

          obj_dados_empresa: null,

          rel_auto_print: false,
          rel_ocultar_header: false,

          relatorios_show: false,

          modelo_recibo: '',

          rel_componente :'',
          rel_headers :'',
          rel_items :'',
          rel_titulo :'',

          dialog_print: true,
  
          items_retorno: [],
          items_retorno_obj: {},
  
          cor_teste:'red',
  
          recebe_obj:{},

          array_to_print:[],
          class_default:' rounded ',
          fields_coluna: 10,
          dados_titulo1 :'',
          dados_titulo2 :'',
  
          soma:0,
  
      }),
  
      created(){
          this.onCreated()
      },
  
      methods:{

        async onCreated(){

          await this.carrega_dados()

          await this.imprimir_modelo()
        },

        mudaVias(vias){
            
            this.objVias = []//limpa

            let obj = {}
            for (let i = 1; i <=vias; i++){
                this.$set(obj, 'n',i)
                this.objVias.push(obj)
            }
        },


        quebra_linha(msg){

          msg = msg.replaceAll('\n', '<br/>')

          return msg
        },

        // PASSO 1 - defino qual abrir
        async imprimir_modelo(){
  
          //pega o parametro verificador
          let qual = this.obj_dados_impressao.qual          

          this.modelo_recibo = qual


          this.mudaVias(this.nVias)


          // abre sql
          if (qual=='RECIBO'){
              
          }
          else if (qual=='Faltas'){
            await this.rel_faltas()
            await this.monta_impressao(qual)
          }
          else{
            await this.monta_impressao(qual)
          }


          ver_auto(this)

          async function ver_auto(that){
             if (that.rel_auto_print){
                that.imprimir()
             }
          }

        },

        desenha(nome, sub_items){
          let obj = {}//clear       
          this.$set(obj, nome, sub_items );
          this.array_to_print.push(obj)
        },

        async monta_impressao(modelo){

          // let space = '⠀⠀⠀⠀⠀⠀⠀⠀⠀'
          let space = '⠀'
          // let space = this.espace

          if (modelo=='Faltas'){
            this.desenha('colunas',
            [ 
              { tag:'div', col_class:'text-center pt-0 mt-5',col_cols:'12', class:'fs-17 ',  LABEL1: this.emp.nome , boldL1:'S' },
              { tag:'div', col_class:'text-center',col_cols:'12', class:'fs-12 ',  LABEL1: this.dados_titulo1 , boldL1:'S' },
              { tag:'div', col_class:'text-center',col_cols:'12', class:'fs-10 ',  LABEL1: this.dados_titulo2 , boldL1:'S' },
              { tag:'divider', col_cols:'12', class:'black my-1 mx-0'},
            ])
            
            this.desenha('sub_tabela',
            [ 
              { tag:'div', col_class:'',col_cols:'4', class:' fs-9', table: this.items_retorno,
                    F1: 'NOME', boldF1:'N', B1: ``,  
              },
              { tag:'div', col_class:'',col_cols:'2', class:' fs-9', table: this.items_retorno,
                    F1: 'CONVENIO', boldF1:'N', B1: ``,  
              },
              { tag:'div', col_class:'',col_cols:'3', class:' fs-8', table: this.items_retorno,
                    F1: 'CELULAR', boldF1:'N', B1: ``,  
                    F2: 'FONE1', boldF1:'N', B2: `⠀`,  
              },

              { tag:'div', col_class:'',col_cols:'2', class:' fs-7', table: this.items_retorno,
                    F1: 'PROCEDIMENTO', boldF1:'N', B1: ``,  
              },

              { tag:'div', col_class:'',col_cols:'1', class:' fs-8', table: this.items_retorno,
                    F1: 'x_DATA', boldF1:'N', B1: ``,  
                    F2: 'ATENDIDO', boldF1:'N', B2: `-`,
              },


              { tag:'divider', col_cols:'12', class:'black my-0 mx-0'},
            ])

            this.desenha('colunas',
            [ 
              { tag:'div', col_class:'text-right pr-2 mb-5',col_cols:'12', class:'fs-12 ',  LABEL1: this.items_retorno.length , boldL1:'S' },
              // { tag:'divider', col_cols:'12', class:'black my-1 mx-0'},
            ])

          }
        },


        async carrega_dados(){


          // CAMPOS OBRIGATORIOS: relatorios_show , qual , 

          let REDIRECIONA = false

          try {this.obj_dados_impressao = JSON.parse(sessionStorage['obj_dados_impressao'])}
          catch (e) { 
            REDIRECIONA = true
          }


          REDIRECIONA          = (!this.obj_dados_impressao.relatorios_show) 

          this.relatorios_show = (this.obj_dados_impressao.relatorios_show) 


          //acesso nao permitido
          if (REDIRECIONA){
              window.location.replace("https://www.google.com") 
              return false
          }



          try { this.obj_dados_empresa = JSON.parse(sessionStorage['obj_dados_empresa']) }
          catch (error) { console.log('erro:', error); }


          this.urlLogo =  sessionStorage['emp_url_logo']
          this.hoje    = get_data_hora('hoje')



          let qual = this.obj_dados_impressao.qual
          
          if (qual == 'AUTO'){
             this.rel_componente     = this.obj_dados_impressao.componente
             
             this.rel_headers        = JSON.parse( this.obj_dados_impressao.headers ) 
             this.rel_headers.forEach(item => delete item.width);//apaga o width

             this.rel_items          = JSON.parse( this.obj_dados_impressao.items ) 
             this.rel_titulo         = this.obj_dados_impressao.titulo
             this.rel_auto_print     = this.obj_dados_impressao.auto_print
             this.rel_ocultar_header = this.obj_dados_impressao.ocultar_header
          }
          else if (qual == 'RECIBO'){
            
            // busca dados do recibo
            try {this.obj_dados_recibo = this.obj_dados_impressao.obj_dados_recibo}
            catch (e) {}

            this.recibo_basico = (this.obj_dados_recibo.TIPO == 'E')

          }

        },


        showDrawer(){
          this.$emit('executar', false)//mostrar ou nao paineis
        },
  
          getSomatoria(array,filtrar_campo, filtrar_valor, campo_somar){
              // console.log(array);
              
                  
              if (filtrar_campo){

              
                 //filtro array pelo campo desejado
                  let h = array.filter( x => x[filtrar_campo] === filtrar_valor );
  
  
                  //faço a soma dos campos desejados
                  var sum = h.reduce(function(accumulator,object){ 
  
                  //converto formato float
                  let tofloat = object[campo_somar]
                  tofloat = tofloat.replaceAll('.','');
                  tofloat = tofloat.replaceAll(',','.');
  
                  return parseFloat(accumulator) + parseFloat(tofloat)
                  // return parseFloat(accumulator) + parseFloat(object[campo_somar])
                  },0);
  
                  return  sum
              }
              else{

                let A = array.map((x) => x); //clonando copiando array do jeito 

                  //faço a soma dos campos desejados
                var sumA = A.reduce(function(accumulator,object){ 
  
                //converto formato float
                let tofloat = object[campo_somar]
                tofloat = tofloat.replaceAll('.','');
                tofloat = tofloat.replaceAll(',','.');

                return parseFloat(accumulator) + parseFloat(tofloat)
                },0);

                return  sumA

              }
          },
  
          formatCurrency(vem){
              let r =  vem.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
              return r
          },
  
  
          display(item,index,array, A,B,C){
  
              let r = false
  
              if (C){
                  r = 
                      (index == 0) || 
                      (index>0 && array[index-1][A] != item[A]) || 
                      (index>0 && array[index-1][B] != item[B]) ||
                      (index>0 && array[index-1][C] != item[C]) 
                  
              }
              else if (B){
                  r = 
                  ((index == 0) || 
                  (index>0 && array[index-1][A] != item[A]) || 
                  (index>0 && array[index-1][B] != item[B]))
  
                  if (r == false){
                      // console.log('B false = '+ index);
                  }else{
                      // console.log('B true = '+ index);
                  }    
              }
              else if (A){
                  r =  (index == 0) || (index>0 && array[index-1][A] != item[A])
  
                  if (r == false){
                      // console.log('A false = '+ index);
                  }else{
                      // console.log('A true = '+ index);
                      // this.mais(index)
                      // return this.soma = index
                      
                  }    
              } 
  
              // count_qt
              return r
              
          },
  
          async rel_faltas(doc,conv,di,df,proced){
  
              doc    = sessionStorage['rel.doc']
              conv   = sessionStorage['rel.conv']
              di     = sessionStorage['rel.di']
              df     = sessionStorage['rel.df']
              proced = sessionStorage['rel.proced']
  
  
              this.$set(this.recebe_obj, 'rel_doc', doc)
              this.$set(this.recebe_obj, 'rel_conv', conv)
              this.$set(this.recebe_obj, 'rel_di', this.getFormatDate(di))
              this.$set(this.recebe_obj, 'rel_df', this.getFormatDate(df))
  
              this.$set(this.recebe_obj, 'rel_prof', sessionStorage.getItem('rel.prof'))

  
  
              let sql_doc = ''
              if (doc>0){
              sql_doc = ` and (agenda.codigomedico = ${doc}) `
              }
  
              let sql_conv = ''
              if (conv>0){
              sql_conv = ` and (agenda.codigoconvenio = ${conv}) `
              }
  
            //   proced = 'ac'.toUpperCase()
              let sql_proced = ''
              if ((proced)&&(proced!='null')){
                sql_proced = ` and (UPPER (agenda.procedimento) LIKE  '%${proced}%') `
              }

  
              let sql = `

              SELECT
                AGENDA.CODIGOCLIENTE,
                AGENDA.DATA,
                AGENDA.HORA,
                AGENDA.ATENDIDO,
                AGENDA.PROCEDIMENTO,
                CLIENTES.NOME,
                CLIENTES.FONE1,
                CLIENTES.FONE2,
                CLIENTES.CELULAR,
                CLIENTES.EMAIL,
                AGENDA.CODIGOMEDICO,
                DOUTORES.NOME AS PROFISSIONAL,
                CONVENIO.NOME AS CONVENIO
              FROM
              AGENDA
              INNER JOIN CLIENTES ON (AGENDA.CODIGOCLIENTE=CLIENTES.CODIGOCLIENTE)
              INNER JOIN DOUTORES ON (AGENDA.CODIGOMEDICO=DOUTORES.CODIGO)
              LEFT OUTER JOIN CONVENIO ON (CLIENTES.CODIGOCONVENIO=CONVENIO.CODIGO)
              WHERE
                (AGENDA.DATA BETWEEN '${di}' AND '${df}') AND 
                (AGENDA.ATENDIDO IN ('F','C'))
                `
                +sql_doc + sql_conv + sql_proced + 
                `
              ORDER BY
                CLIENTES.NOME`

  
              // console.log(sql);
              await this.crud_abrir_tabela(sql, 'items_retorno' )
              // console.log(this.items_retorno);
  
  
          },
  
          async rel_atendimentos(doc,conv,di,df,proced){
  
              doc    = sessionStorage['rel.doc']
              conv   = sessionStorage['rel.conv']
              di     = sessionStorage['rel.di']
              df     = sessionStorage['rel.df']
              proced = sessionStorage['rel.proced']
  
  
              this.$set(this.recebe_obj, 'rel_doc', doc)
              this.$set(this.recebe_obj, 'rel_conv', conv)
              this.$set(this.recebe_obj, 'rel_di', this.getFormatDate(di))
              this.$set(this.recebe_obj, 'rel_df', this.getFormatDate(df))
  
              this.$set(this.recebe_obj, 'rel_prof', sessionStorage.getItem('rel.prof'))
  
              // console.log('doc:'+doc);
              // console.log('conv:'+conv);
              // console.log('di:'+di);
              // console.log('df:'+df);
  
  
              let sql_doc = ''
              if (doc>0){
              sql_doc = ` and (agenda.codigomedico = ${doc}) `
              }
  
              let sql_conv = ''
              if (conv>0){
              sql_conv = ` and (agenda.codigoconvenio = ${conv}) `
              }
  
            //   proced = 'ac'.toUpperCase()
              let sql_proced = ''
              if ((proced)&&(proced!='null')){
                sql_proced = ` and (UPPER (agenda.procedimento) LIKE  '%${proced}%') `
              }
  

              let concluidos = ` AND (AGENDA.ATENDIDO NOT IN ('F','C'))`
              if (this.checkbox_concluidos){
                  concluidos = ` AND (AGENDA.ATENDIDO IN ('G','A','T'))`
              }

  
              let sql = `
              SELECT
                AGENDA.DATA,
                AGENDA.HORA,
                AGENDA.NOME AS NOME_AGENDA,
                CLIENTES.CELULAR,
                CLIENTES.NOME,
                CLIENTES.CODIGOCLIENTE,
                AGENDA.CONVENIO,
                AGENDA.PROCEDIMENTO,
                AGENDA.VALOR,
                AGENDA.OBS,
                '1' AS CONTAR
              FROM
               AGENDA
              LEFT OUTER JOIN CLIENTES ON (AGENDA.CODIGOCLIENTE=CLIENTES.CODIGOCLIENTE)
              WHERE
               (AGENDA.DATA BETWEEN '${di}' AND '${df}') 
               ${concluidos}
               `
               +sql_doc + sql_conv + sql_proced + 
               `
              ORDER BY
              AGENDA.CONVENIO,
              AGENDA.PROCEDIMENTO,
              AGENDA.DATA,
              AGENDA.HORA
              `
  
              console.log(sql);
              await this.crud_abrir_tabela(sql, 'items_retorno' )
              // console.log(this.items_retorno);
  
  
          },
  
          imprimir(item){       
              // setPrintStyle('portrait')

              this.imprimindo = true

              setTimeout(function(){
                window.print()
              }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout

              setTimeout(function(){
                this.imprimindo = false
              }.bind(this), 1000);//usar esse .bind(this) para chamar funcoes com o setTimeout 
          },
  
          close(){
              // this.dialog_print = false
              // this.$router.push('/agenda')//redireciono para a pagina do router
              window.close()
          },
  
          getFormatDate(data){
           let dia =   data.substring(8,10) + '/'+ data.substring(5,7)+ '/'+ data.substring(0,4);//formato a dd/mm/yyyy
           return dia 
        },
  
  
          async crud_abrir_tabela(sql, items_name){
             await DSisql.crud_abrir_tabela(this, sql, items_name)
          // console.log(this.items_mensagens);
          }, 
      }
  }
  
  </script>
  
  <style lang="scss">
  

    .container{
      max-width: 100%;
      // color: red;
    }
  
  
    .inline{
    display: inline  !important;
  }
  
  .cap{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
  }
  
  .none{
    font-weight: normal;
    /* font-weight (bold, bolder, lighter, normal e 100-900 */
  }
  
  .h7{
    font-size: 10px !important;
    /* color: red !important; */
  }
  .cap_soma{
    font-size: 12px !important;
    /* color: red !important; */
    display: inline;
    font-weight: bold;
  }
  
  .header{
      background-color: #E0E0E0;
      -webkit-print-color-adjust: exact; /* força imprimir com a cor defina aqui */
  }
  
  .sem_borda{
       /* sem borda */
      border: none !important;
      box-shadow: none !important;
  }
  
  @media print {
    .no-print {
      display: none !important;
    }



          /* tamanho da fonte do data-table */
    .v-data-table th {
      font-size: 9px !important;
    }
    .v-data-table td {
      font-size: 9px !important;
    }
    /* tamanho da fonte do data-table */

  
    // .full{
    //   width: 95%; /* preenche toda a tela */
    // }

    // // // resolve o problema de diferentes margens do dev pro build
    // body {
    //   margin: 0.5cm;
    //   padding: 0;
    // }
  }


  @page:first {
     margin-top: 0;           
  }  

  @page {
    size: auto;
    margin: 0.7cm;
    //  margin-top: -2cm; 
    /* change the margins as you want them to be. */
  }


  .footer_class{
    position: absolute !important;
    bottom: 0 !important;
    width: 100% !important;
  }
  

  .fs-7{
    font-size: 7px !important;
  }
  .fs-8{
    font-size: 8px !important;
  }
  .fs-9{
    font-size: 9px !important;
  }
  .fs-10{
    font-size: 10px !important;
  }
  .fs-11{
    font-size: 11px !important;
  }
  .fs-12{
    font-size: 12px !important;
  }
  .fs-13{
    font-size: 13px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }
  .fs-15{
    font-size: 15px !important;
  }
  .fs-16{
    font-size: 16px !important;
  }
  .fs-17{
    font-size: 17px !important;
  }
  .fs-18{
    font-size: 18px !important;
  }
  .fs-20{
    font-size: 20px !important;
  }
  .fs-25{
    font-size: 25px !important;
  }
  .fs-30{
    font-size: 30px !important;
  }

  .bold{
    font-weight: bold !important;
  }
  
  .underline{
    text-decoration: underline !important;
  }



   td.text-start{ /* padding das colunas data-table  */
    padding-left: 1px !important;
    padding-right: 1px !important;
    /* padding: 0px 2px !important; */
    // background-color: red; 
    font-size: 10px !important;
   }

   th.text-start{ /* padding dos titulos das colunas data-table */
    // padding: 0px 1px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    /* background-color: red; */
  }

  
  /* lang="scss" */
  </style>
